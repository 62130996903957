/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import sanitizeHtml from "sanitize-html";
import { loadPitchData } from "@/redux/actions/createPitchActions";
import {
  showActivatePitchModal,
  showActivateIntroModal
} from "@/redux/actions/globalStateActions";
import { captureEventForAnalytics } from "@/services/utilitiesService";
import "./NotificationCard.scss";

const defaultAvatar =
  "https://esocialbucket.s3.ap-south-1.amazonaws.com/placeholders/Group+11662.png";
function NotificationCard(data) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [content, setContent] = useState({});
  const [time, setTime] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const showToast = (msg) => {
    setToastMessage(msg);
  };
  const hideToast = () => {
    setToastMessage("");
  };
  useEffect(() => {
    if (toastMessage !== "") {
      setTimeout(hideToast, 2000);
    }
  }, [toastMessage]);
  useEffect(() => {
    let timeString = "";
    const earlierTime = data.data.time;
    if (earlierTime.includes("a few seconds ago"))
      timeString = earlierTime.replace("a few seconds ago", "Just Now");
    else if (earlierTime.includes("a minute ago"))
      timeString = earlierTime.replace("a minute ago", "1m");
    else if (earlierTime.includes(" minutes ago"))
      timeString = earlierTime.replace(" minutes ago", "m");
    else if (earlierTime.includes("an hour ago"))
      timeString = earlierTime.replace("an hour ago", "1h");
    else if (earlierTime.includes(" hours ago"))
      timeString = earlierTime.replace(" hours ago", "h");
    else if (earlierTime.includes("a day ago"))
      timeString = earlierTime.replace("a day ago", "1d");
    else if (earlierTime.includes(" days ago"))
      timeString = earlierTime.replace(" days ago", "d");
    else if (earlierTime.includes("a month ago"))
      timeString = earlierTime.replace("a month ago", "1mo");
    else if (earlierTime.includes(" months ago"))
      timeString = earlierTime.replace(" months ago", "mo");
    else if (earlierTime.includes("a year ago"))
      timeString = earlierTime.replace("a year ago", "1y");
    else if (earlierTime.includes(" years ago"))
      timeString = earlierTime.replace(" years ago", "y");
    // console.log(timeString)
    setTime(timeString);
    setContent(data.data);
    // rgba(112, 181, 249, 0.2)
  }, [data]);
  const handleAnalytics = () => {
    captureEventForAnalytics({
      eventType: "notification_click",
      eventData: {
        notificationId: data.data.userNotificationId,
        fromPanel: false,
        deviceType: "web"
      }
    });
  };
  const sendToChat = () => {
    const candidate = {
      name: content.sourceName,
      image_id: content.sourceUserImageId,
      user_id: content.sourceUserId
    };
    sessionStorage.setItem("chatWithCandidate", JSON.stringify(candidate));
    history.push("/chat");
    handleAnalytics();
  };
  const sendToBloodChat = () => {
    const candidate = {
      name: content.sourceName,
      image_id: content.sourceUserImageId,
      user_id: content.sourceUserId
    };
    sessionStorage.setItem("chatWithCandidate", JSON.stringify(candidate));
    history.push("/blood-donors/chat");
    handleAnalytics();
  };
  const sendToParticularExpPage = () => {
    history.push(`/social/my-posts/${content.socialExpId}`);
    handleAnalytics();
  };
  const sendToSocialChat = () => {
    const candidate = {
      name: content.sourceName,
      image_id: content.sourceUserImageId,
      user_id: content.sourceUserId
    };
    sessionStorage.setItem("chatWithCandidate", JSON.stringify(candidate));
    history.push("/social/app");
    handleAnalytics();
  };
  const profileRedirect = (data) => {
    console.log(data);
    const userKeyword = `${
      data.targetUserName
        ? data.targetUserName.replaceAll(" ", "-").toLowerCase()
        : ""
    }-${data.targetUserId}`;
    history.push(`/${userKeyword}`);
    handleAnalytics();
  };
  const handleButtonClick = () => {
    // eslint-disable-next-line
    handleAnalytics();
    const value = content.notificationType;
    if (
      value === "JOB_APPLICATION_ACCEPT" ||
      value === "HIRE_NOW" ||
      value === "HIRE_NOW_NEW" ||
      value === "HIRE_OFFER_ACCEPT" ||
      value === "TG_OFFER_ACCEPTED" ||
      value?.includes("TG_OFFER_ACCEPTED_REMINDER_") ||
      value === "TG_JOB_APPLICATION_ACCEPTED" ||
      value?.includes("TG_JOB_APPLICATION_ACCEPTED_REMINDER_") ||
      value === "TG_OFFER_REMINDER" ||
      value === "TG_OFFER_ACCEPTED" ||
      value === "TG_PITCH_ACCEPTED" ||
      value === "TG_PITCH_RECEIVED" ||
      value === "TG_PITCH_RECEIVED_REMINDER_1" ||
      value === "TG_JOB_APPLICATION_ACCEPTED"
    ) {
      sendToChat();
    } else if (
      value === "TG_OFFER_RECEIVED" ||
      value.includes("TG_OFFER_RECEIVED_REMINDER_")
    ) {
      sendToChat();
    } else if (
      value === "TG_CONNECT_REQUEST_RECEIVED" ||
      value === "TG_CONNECT_REQUEST_REJECTED" ||
      value === "TG_CONNECT_REQUEST_ACCEPTED" ||
      value === "TG_EXPERIENCE_INTEREST_ACCEPTED" ||
      value === "TG_EXPERIENCE_INTEREST_REJECTED"
    ) {
      sendToSocialChat();
    } else if (
      value === "TG_BLOOD_DONOR_REQUEST_RECEIVED" ||
      value === "TG_BLOOD_DONOR_REQUEST_ACCEPTED"
    ) {
      sendToBloodChat();
    } else if (value === "TG_BLOOD_DONOR_REQUEST_REJECTED") {
      history.push("/blood-donors");
    } else if (value === "JOB_APPLICATION_REJECT") {
      history.push("/jobs");
    } else if (value === "JOB_APPLY") {
      const userKeyword = `${
        content.sourceName
          ? content.sourceName.replaceAll(" ", "-").toLowerCase()
          : ""
      }-${content.sourceUserId}`;
      history.push(`/${userKeyword}`);
    } else if (content.notificationType?.includes("TG_INTERVIEW_INVITE")) {
      history.push(`/interview-v3/${content.offerId}/${content.hash}`);
    } else if (
      value === "TG_FEEDBACK_REQUEST" ||
      content.notificationType === "TG_FEEDBACK_RECEIVED"
    ) {
      if (content.canReviewBack === 1)
        history.push(`/feedbackUser/${content.sourceUserId}?status=3`);
      else
        showToast(`You can only give feedback to people in your contact list`);
    } else if (value === "TG_JOB_APPLICATION_REJECTED") {
      history.push("/jobs");
    } else if (value === "TG_JOB_NEW_APPLICATION") {
      const userKeyword = `${content.sourceName
        .replaceAll(" ", "-")
        .toLowerCase()}-${content.sourceUserId}`;
      history.push(`/${userKeyword}?offerId=${content.offerId}`);
    } else if (
      value === "TG_JOB_EXPIRY_REMINDER" ||
      value === "TG_JOB_NEW_APPLICATION_COUNTS"
    ) {
      history.push(`/manage-jobs/${content.offerId}`);
    } else if (
      value === "TG_JOB_EXPIRED" ||
      value?.includes("UE_REMIND_JOB_POST_")
    ) {
      history.push(`/create-job-post`);
    } else if (value === "TG_NEW_JOB_ALERT") {
      history.push(
        `/jobs/${content.designation.replaceAll(" ", "-")}?id=${
          content.offerId
        }`
      );
    } else if (
      value === "TG_JOB_REJECTED_UNPAID" ||
      value?.includes("UE_REMIND_ACTIVATE_JOB_POST_")
    )
      history.push("/premium/hirer");
    else if (value === "TG_PITCH_REJECTED") history.push("/create-pitch");
    else if (value === "TG_PITCH_REJECTED_BY_ADMIN") {
      dispatch(showActivatePitchModal());
    } else if (value === "TG_INTRO_REJECTED_BY_ADMIN") {
      dispatch(showActivateIntroModal());
    } else if (value === "TG_PITCH_APPROVED_BY_ADMIN") {
      console.priorityLog(content);
      dispatch(
        loadPitchData({
          pitchId: content.pitchId,
          pitchDetails: null
        })
      );
      history.push("/preview-pitch");
    } else if (value === "TG_JOB_REJECTED") {
      sessionStorage.setItem(
        "jobDataReactivate",
        JSON.stringify({ offerId: content.offerId })
      );
      history.push("/create-job-post");
    } else {
      history.push("/suggested-candidates");
    }
  };
  return (
    <div
      className="NotificationCard__container"
      style={{ backgroundColor: content.isShown === 0 ? "#BBD3FF" : "#FFF" }}
    >
      <div className="NotificationCard__photo">
        <img
          src={content.sourceUserImageId || defaultAvatar}
          alt=""
          className="NotificationCard__photo-img"
        />
      </div>
      <div className="NotificationCard__content">
        <div>
          {content.notificationType === "UE_SIGN_UP_JOB_SEEKER" ? (
            <span
              onClick={() => profileRedirect(content)}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "UE_REMINDER_PROFILE_1" ? (
            <span
              onClick={() => profileRedirect(content)}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "UE_REMINDER_PROFILE_2" ? (
            <span
              onClick={() => profileRedirect(content)}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "UE_REMINDER_PROFILE_3" ? (
            <span
              onClick={() => profileRedirect(content)}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "UE_REMINDER_PROFILE_4" ? (
            <span
              onClick={() => profileRedirect(content)}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "UE_REMINDER_PROFILE_5" ? (
            <span
              onClick={() => profileRedirect(content)}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "UE_REMINDER_PROFILE_6" ? (
            <span
              onClick={() => profileRedirect(content)}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "UE_REMINDER_REQUEST_FEEDBACK_1" ? (
            <span
              onClick={() => {
                handleAnalytics();
                history.push("/feedback");
              }}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "UE_REMINDER_REQUEST_FEEDBACK_2" ? (
            <span
              onClick={() => {
                handleAnalytics();
                history.push("/feedback");
              }}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "UE_REMINDER_REQUEST_FEEDBACK_3" ? (
            <span
              onClick={() => {
                handleAnalytics();
                history.push("/feedback");
              }}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "UE_REMINDER_REQUEST_FEEDBACK_4" ? (
            <span
              onClick={() => {
                handleAnalytics();
                history.push("/feedback");
              }}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "UE_REMINDER_GIVE_FEEDBACK_1" ? (
            <span
              onClick={() => {
                handleAnalytics();
                history.push("/feedback");
              }}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "UE_REMINDER_GIVE_FEEDBACK_2" ? (
            <span
              onClick={() => {
                handleAnalytics();
                history.push("/feedback");
              }}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "TG_FEEDBACK_RECEIVED" ? (
            <span
              onClick={handleButtonClick}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "UE_REMINDER_HIRE_1" ? (
            <span
              onClick={handleButtonClick}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "UE_REMINDER_HIRE_2" ? (
            <span
              onClick={handleButtonClick}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "TG_PITCH_REJECTED_BY_ADMIN" ||
            content.notificationType === "TG_PITCH_APPROVED_BY_ADMIN" ||
            content.notificationType === "TG_INTRO_REJECTED_BY_ADMIN" ||
            content.notificationType === "TG_INTRO_APPROVED_BY_ADMIN" ||
            content.notificationType === "TG_PITCH_REJECTED" ? (
            <span
              onClick={handleButtonClick}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "UE_REMINDER_HIRE_3" ? (
            <span
              onClick={handleButtonClick}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "UE_REMINDER_POST_JOB_1" ? (
            <span
              onClick={() => {
                handleAnalytics();
                history.push("/my_job_posts/true");
              }}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "UE_REMINDER_POST_JOB_2" ? (
            <span
              onClick={() => {
                handleAnalytics();
                history.push("/my_job_posts/true");
              }}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "TG_PREMIUM_WELCOME" ? (
            <span
              onClick={() => {
                handleAnalytics();
                history.push("/premium");
              }}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "TG_CONTACT_JOIN" ? (
            <span
              onClick={() => {
                handleAnalytics();
                history.push(`/feedbackUser/${content.sourceUserId}?status=3`);
              }}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "TG_OFFER_EXPIRED" ? (
            <span
              onClick={() => {
                handleAnalytics();
                history.push("/hire");
              }}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "UE_REMINDER_GCOINS" ? (
            <span
              onClick={() => {
                profileRedirect(content);
              }}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "TG_CONNECT_REQUEST_RECEIVED" ||
            content.notificationType === "TG_CONNECT_REQUEST_REJECTED" ||
            content.notificationType === "TG_CONNECT_REQUEST_ACCEPTED" ||
            content.notificationType === "TG_EXPERIENCE_INTEREST_ACCEPTED" ||
            content.notificationType === "TG_EXPERIENCE_INTEREST_REJECTED" ? (
            <span
              onClick={() => {
                sendToSocialChat(content);
              }}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : content.notificationType === "TG_EXPERIENCE_NEW_INTEREST" ? (
            <span
              onClick={() => {
                sendToParticularExpPage();
              }}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          ) : (
            <span
              onClick={() => {
                if (
                  content.notificationType ===
                    "TG_BLOOD_DONOR_REQUEST_ACCEPTED" ||
                  content.notificationType === "TG_BLOOD_DONOR_REQUEST_RECEIVED"
                ) {
                  handleAnalytics();
                  sendToBloodChat();
                }
              }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content.body)
              }}
            />
          )}
        </div>
        <button
          className="NotificationCard__content-button"
          value={content.notificationType}
          onClick={handleButtonClick}
          style={
            content.notificationType === "JOB_APPLICATION_ACCEPT" ||
            content.notificationType === "JOB_APPLICATION_REJECT" ||
            content.notificationType === "JOB_APPLY" ||
            content.notificationType === "HIRE_NOW" ||
            content.notificationType === "HIRE_NOW_NEW" ||
            content.notificationType === "HIRE_OFFER_ACCEPT" ||
            content.notificationType === "HIRE_OFFER_REJECT" ||
            content.notificationType === "UE_SIGN_UP_HIRER" ||
            content.notificationType === "TG_FEEDBACK_REQUEST" ||
            content.notificationType === "TG_OFFER_RECEIVED" ||
            content.notificationType?.includes("TG_OFFER_RECEIVED_REMINDER_") ||
            content.notificationType === "TG_OFFER_REJECTED" ||
            content.notificationType === "TG_OFFER_ACCEPTED" ||
            content.notificationType?.includes("TG_OFFER_ACCEPTED_REMINDER_") ||
            content.notificationType === "TG_OFFER_REMINDER" ||
            content.notificationType === "TG_JOB_NEW_APPLICATION" ||
            content.notificationType === "TG_JOB_APPLICATION_ACCEPTED" ||
            content.notificationType?.includes(
              "TG_JOB_APPLICATION_ACCEPTED_REMINDER_"
            ) ||
            content.notificationType?.includes(
              "UE_REMIND_ACTIVATE_JOB_POST_"
            ) ||
            content.notificationType?.includes("UE_REMIND_JOB_POST_") ||
            content.notificationType === "TG_JOB_APPLICATION_REJECTED" ||
            content.notificationType === "TG_JOB_EXPIRY_REMINDER" ||
            content.notificationType === "TG_NEW_JOB_ALERT" ||
            content.notificationType === "TG_JOB_EXPIRED" ||
            content.notificationType === "TG_JOB_REJECTED" ||
            content.notificationType === "TG_PITCH_ACCEPTED" ||
            content.notificationType === "TG_PITCH_RECEIVED" ||
            content.notificationType === "TG_PITCH_RECEIVED_REMINDER_1" ||
            content.notificationType === "TG_PITCH_APPROVED_BY_ADMIN" ||
            content.notificationType === "TG_PITCH_REJECTED_BY_ADMIN" ||
            content.notificationType === "TG_INTRO_APPROVED_BY_ADMIN" ||
            content.notificationType === "TG_INTRO_REJECTED_BY_ADMIN" ||
            content.notificationType === "TG_PITCH_REJECTED" ||
            content.notificationType === "TG_JOB_REJECTED_UNPAID" ||
            content.notificationType === "TG_JOB_NEW_APPLICATION_COUNTS" ||
            content.notificationType === "TG_BLOOD_DONOR_REQUEST_ACCEPTED" ||
            content.notificationType === "TG_BLOOD_DONOR_REQUEST_RECEIVED" ||
            content.notificationType?.includes("TG_INTERVIEW_INVITE") ||
            content.notificationType === "TG_BLOOD_DONOR_REQUEST_REJECTED"
              ? null
              : { display: "none" }
          }
        >
          {content.notificationType === "JOB_APPLICATION_ACCEPT" ||
          content.notificationType === "HIRE_NOW" ||
          content.notificationType === "HIRE_NOW_NEW" ||
          content.notificationType === "HIRE_OFFER_ACCEPT" ||
          content.notificationType === "TG_OFFER_ACCEPTED" ||
          content.notificationType?.includes("TG_OFFER_ACCEPTED_REMINDER") ||
          content.notificationType === "TG_JOB_APPLICATION_ACCEPTED" ||
          content.notificationType?.includes(
            "TG_JOB_APPLICATION_ACCEPTED_REMINDER_"
          )
            ? "Message"
            : content.notificationType?.includes("UE_REMIND_ACTIVATE_JOB_POST_")
            ? "Go Premium"
            : content.notificationType === "JOB_APPLICATION_REJECT"
            ? "View Jobs"
            : content.notificationType === "JOB_APPLY" ||
              content.notificationType === "TG_JOB_NEW_APPLICATION"
            ? "View Profile"
            : content.notificationType === "UE_SIGN_UP_HIRER" ||
              content.notificationType === "TG_OFFER_REJECTED"
            ? "Hire Talent"
            : content.notificationType === "TG_FEEDBACK_REQUEST"
            ? "Give Feedback"
            : content.notificationType === "TG_OFFER_RECEIVED" ||
              content.notificationType?.includes(
                "TG_OFFER_RECEIVED_REMINDER_"
              ) ||
              content.notificationType === "TG_OFFER_REMINDER"
            ? "Respond"
            : content.notificationType === "TG_JOB_APPLICATION_REJECTED"
            ? "Find Work"
            : content.notificationType === "TG_JOB_EXPIRED" ||
              content.notificationType?.includes("UE_REMIND_JOB_POST_")
            ? "Create Job"
            : content.notificationType === "TG_JOB_EXPIRY_REMINDER"
            ? "Manage Job"
            : content.notificationType === "TG_JOB_NEW_APPLICATION_COUNTS" ||
              content.notificationType === "TG_PITCH_ACCEPTED" ||
              content.notificationType === "TG_PITCH_RECEIVED" ||
              content.notificationType === "TG_PITCH_RECEIVED_REMINDER_1" ||
              content.notificationType === "TG_BLOOD_DONOR_REQUEST_ACCEPTED" ||
              content.notificationType === "TG_BLOOD_DONOR_REQUEST_RECEIVED"
            ? "Connect"
            : content.notificationType === "TG_NEW_JOB_ALERT"
            ? "View Job"
            : content.notificationType === "TG_PITCH_APPROVED_BY_ADMIN"
            ? "View Preview"
            : content.notificationType === "TG_JOB_REJECTED" ||
              content.notificationType === "TG_PITCH_REJECTED_BY_ADMIN" ||
              content.notificationType === "TG_INTRO_REJECTED_BY_ADMIN" ||
              content.notificationType === "TG_JOB_REJECTED_UNPAID"
            ? "Rectify"
            : content.notificationType === "TG_PITCH_REJECTED"
            ? "Create Pitch"
            : content.notificationType === "TG_INTRO_REJECTED"
            ? "Create Intro"
            : content.notificationType?.includes("TG_INTERVIEW_INVITE")
            ? "Start Interview"
            : content.notificationType === "TG_BLOOD_DONOR_REQUEST_REJECTED"
            ? "Find Other Donors"
            : "Hire Now"}
        </button>
      </div>
      <div className="NotificationCard__time">{time}</div>
      {toastMessage && (
        <div className="NotificationCard__toastMessage">{toastMessage}</div>
      )}
    </div>
  );
}

export default NotificationCard;
