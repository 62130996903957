// userActions
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_USER_AS_ACTIVE = "SET_USER_AS_ACTIVE";
export const UPDATE_PFP = "UPDATE_PFP";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";

// filterActions
export const UPDATE_FILTER_MAIN_HIRE = "UPDATE_FILTER_MAIN_HIRE";
export const UPDATE_FILTER_OTHER_HIRE = "UPDATE_FILTER_OTHER_HIRE";
export const SET_FILTER_BY_HIRE = "SET_FILTER_BY_HIRE";
export const UPDATE_NAME_INPUT_VALUE_HIRE = "UPDATE_NAME_INPUT_VALUE_HIRE";
export const UPDATE_TOF_INPUT_VALUE_HIRE = "UPDATE_TOF_INPUT_VALUE_HIRE";
export const RESET_STATE_HIRE = "RESET_STATE_HIRE";
export const UPDATE_FILTER_MAIN_JOB = "UPDATE_FILTER_MAIN_JOB";
export const UPDATE_FILTER_OTHER_JOB = "UPDATE_FILTER_OTHER_JOB";
export const SET_FILTER_BY_JOB = "SET_FILTER_BY_JOB";
export const UPDATE_NAME_INPUT_VALUE_JOB = "UPDATE_NAME_INPUT_VALUE_JOB";
export const UPDATE_TOF_INPUT_VALUE_JOB = "UPDATE_TOF_INPUT_VALUE_JOB";
export const RESET_STATE_JOB = "RESET_STATE_JOB";
export const SET_PAGE_HIRE = "SET_PAGE_HIRE";
export const SET_LAST_PAGE_HIRE = "SET_LAST_PAGE_HIRE";
export const SET_LAST_PAGE_JOB = "SET_LAST_PAGE_JOB";
export const SET_PAGE_JOB = "SET_PAGE_JOB";
export const SET_FILTER_BY_OFFER_ID = "SET_FILTER_BY_OFFER_ID";
export const SET_FILTER_CATEGORY = "SET_FILTER_CATEGORY";

// staticConfigActions
export const LOAD_STATIC_CONFIG = "LOAD_STATIC_CONFIG";

// specificConfigActions
export const ADD_SPECIFIC_CONFIG = "ADD_SPECIFIC_CONFIG";

// notificationActions
export const SET_NOTIFICATION_COUNT_DATA = "SET_NOTIFICATION_COUNT_DATA";

export const SET_TOAST_MESSAGE = "SET_TOAST_MESSAGE";
export const SET_ONBOARDING_STATUS = "SET_ONBOARDING_STATUS";
export const SHOW_INSUFFICIENT_GCOINS_MODAL = "SHOW_INSUFFICIENT_GCOINS_MODAL";
export const HIDE_INSUFFICIENT_GCOINS_MODAL = "HIDE_INSUFFICIENT_GCOINS_MODAL";

export const LOAD_PITCH_DATA = "LOAD_PITCH_DATA";
export const LOAD_INTRO_DATA = "LOAD_INTRO_DATA";

export const SHOW_ACTIVATE_PITCH_MODAL = "SHOW_ACTIVATE_PITCH_MODAL";
export const HIDE_ACTIVATE_PITCH_MODAL = "HIDE_ACTIVATE_PITCH_MODAL";
export const SHOW_ACTIVATE_INTRO_MODAL = "SHOW_ACTIVATE_INTRO_MODAL";
export const HIDE_ACTIVATE_INTRO_MODAL = "HIDE_ACTIVATE_INTRO_MODAL";

// ThemeActions
export const TOGGLE_THEME = "TOGGLE_THEME";